import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import logo from "../assets/logo-horizontal-blue.png";
import mTheme from "../moodyTheme";

const SimpleTopAppBar: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#0f1719",
          boxShadow: "none",
          padding: "0 1rem",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100px",
          }}
        >
          {/* Logo Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <a
              href="https://www.theneptuneapp.com"
              style={{ textDecoration: "none" }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: "60px",
                  cursor: "pointer",
                }}
              />
            </a>
          </Box>

          {/* Contact Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <IconButton
              href="mailto:support@theneptuneapp.com"
              sx={{
                color: "#4FC6DB",
              }}
              aria-label="email"
            >
              <SendIcon
                sx={{
                  transform: "rotate(-45deg)",
                  stroke: "#4FC6DB",
                  strokeWidth: 2,
                }}
              />
            </IconButton>
            <Typography
              component="a"
              href="mailto:support@theneptuneapp.com"
              sx={{
                textDecoration: "none",
                color: mTheme.colors.moodyBlue,
                fontFamily: "Poppins, sans-serif",
                fontSize: "18px",
                fontWeight: "medium",
              }}
            >
              Contact
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SimpleTopAppBar;
