import React, { useState, useRef, useEffect } from "react";
import { Box, Container, Typography, TextField, Button } from "@mui/material";
import SimpleTopAppBar from "../simpleTopAppBarr";
import SimpleFooter from "../simpleFooter";
import theme from "../../moodyTheme";
// import React, { useRef, useEffect } from "react";
// import { Box, Container, Typography, Button } from "@mui/material";
// import SimpleTopAppBar from "../simpleTopAppBarr";
// import SimpleFooter from "../simpleFooter";
// import theme from "../../moodyTheme";

const ReserveHandlePage: React.FC = () => {
  const topAppBarRef = useRef<HTMLDivElement | null>(null);
  const [topAppBarHeight, setTopAppBarHeight] = useState<number>(100); // Default AppBar height

  useEffect(() => {
    if (topAppBarRef.current) {
      setTopAppBarHeight(topAppBarRef.current.offsetHeight || 100);
    }
  }, []);

  const redirectToMainPage = () => {
    window.location.href = "https://theneptuneapp.com";
  };

  return (
    <>
      <div ref={topAppBarRef}>
        <SimpleTopAppBar />
      </div>
      <Box
        sx={{
          width: "100vw",
          minHeight: `calc(100vh - ${topAppBarHeight}px)`,
          paddingTop: `${topAppBarHeight}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.colors.moodyDarkBackground,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            textAlign: "center",
            marginTop: "3rem",
            backgroundColor: "#1A1A1D",
            padding: "2rem",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
            color: theme.colors.moodyWhite,
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontFamily: "Quicksand, sans-serif",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Username Reservations are Closed
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins, sans-serif",
              marginBottom: "2rem",
            }}
          >
            Thank you for your interest in reserving a username for the Neptune
            app. Reservations are currently closed.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Poppins, sans-serif",
              marginBottom: "2rem",
              color: "#b0b0b0",
            }}
          >
            Please visit our main website for updates about the Neptune app.
          </Typography>
          <Button
            variant="contained"
            onClick={redirectToMainPage}
            sx={{
              textTransform: "none",
              backgroundColor: theme.colors.moodyBlue,
              color: theme.colors.moodyDarkBackground,
              fontSize: "16px",
              fontWeight: "bold",
              padding: "10px 20px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: theme.colors.darkButtonHover,
              },
            }}
          >
            Go to Main Page
          </Button>
        </Container>
      </Box>
      <SimpleFooter />
    </>
  );
};

export default ReserveHandlePage;
