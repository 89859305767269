import React from "react";
import { Box, Typography, Container, IconButton, Link } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import logo from "../assets/logo-horizontal-blue.png";
import mTheme from "../moodyTheme";

const SimpleFooter: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: mTheme.colors.moodyDarkBackground,
        padding: "2rem 0",
        borderTop: "4px solid #00B4D8",
        textAlign: "center",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        {/* Logo */}
        <a
          href="https://www.theneptuneapp.com"
          style={{ textDecoration: "none" }}
        >
          <Box
            component="img"
            src={logo}
            alt="Neptune logo"
            sx={{ height: "60px", cursor: "pointer" }}
          />
        </a>

        {/* Contact Email */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            color: mTheme.colors.moodyBlue,
          }}
        >
          <IconButton
            href="mailto:support@theneptuneapp.com"
            sx={{
              color: "#4FC6DB",
            }}
            aria-label="email"
          >
            <SendIcon
              sx={{
                transform: "rotate(-45deg)",
                stroke: "#4FC6DB",
                strokeWidth: 2,
              }}
            />
          </IconButton>
          <Typography
            component="a"
            href="mailto:support@theneptuneapp.com"
            sx={{
              textDecoration: "none",
              color: mTheme.colors.moodyBlue,
              fontFamily: "Poppins, sans-serif",
              fontSize: "18px",
              fontWeight: "medium",
            }}
          >
            support@theneptuneapp.com
          </Typography>
        </Box>

        {/* Footer Links */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Link
            href="/privacy-policy"
            sx={{
              color: "#4FC6DB",
              textDecoration: "none",
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
            }}
          >
            Privacy Policy
          </Link>
          <Link
            href="/terms-of-use"
            sx={{
              color: "#4FC6DB",
              textDecoration: "none",
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
            }}
          >
            Terms of Use
          </Link>
        </Box>

        {/* Footer Text */}
        <Typography
          variant="body2"
          sx={{
            color: "#b0b0b0",
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            marginTop: "1rem",
          }}
        >
          © 2024 Neptune. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default SimpleFooter;
