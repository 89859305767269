import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Image from "../../assets/400x200.png";
import moodyRow from "../../assets/rowing-moody.png";
import theme from "../../moodyTheme";
const JoinList: React.FC = () => {
  return (
    <Box id="join-list" sx={theme.joinListBoxTheme}>
      <Box component="img" src={moodyRow} sx={theme.joinListImageStyles} />
      <Typography sx={theme.joinListTitleStyles}>
        Be the First to Experience Neptune
      </Typography>
      <Typography sx={theme.joinListBodyStyles}>
        Get early access to our beta launch and join a community that puts
        creativity first.
      </Typography>
      <Button
        href="https://docs.google.com/forms/d/e/1FAIpQLSfBFlIeVrQhF3PVdlBOwRSNkdv8HUm_K_hgppMXYDaz5zKYRg/viewform?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
        sx={theme.joinListButtonStyles}
      >
        Beta Test the Revolution
      </Button>
    </Box>
  );
};

export default JoinList;
