import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import UnsubscribePage from "./components/pages/UnsubscribePage";
import ReservationLogin from "./components/pages/ReservationLogin";
import ServerOverload from "./components/pages/ServerOverload";
import MainPage from "./components/mainPage";
import PrivacyPolicy from "./components/notes/PrivacyPolicy";
import TermsOfUse from "./components/notes/TermsofUse";
import ReserveHandlePage from "./components/pages/reserveHandle";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00B4D8",
    },
    secondary: {
      main: "#90E0EF",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

// Redirect component for external URL
const RedirectToDiscord: React.FC = () => {
  React.useEffect(() => {
    window.location.href = "https://discord.gg/theneptuneapp";
  }, []);
  return null;
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/discord" element={<RedirectToDiscord />} />
          <Route path="/*" element={<MainPage />} />
          <Route
            path="/000040d6-efd8-4f79-9441-5db2b29deac5"
            element={<ReserveHandlePage />}
          />
          <Route path="/testing1234" element={<ReserveHandlePage />} />
          <Route path="/reserve" element={<ReserveHandlePage />} />
          <Route path="*" element={<h1>404: Page Not Found</h1>} />
          <Route path="/unsubscribe" element={<UnsubscribePage />} />
          <Route path="/overload" element={<ServerOverload />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
