import React from "react";
import { Box, Container, Typography } from "@mui/material";
import SimpleHeader from "../simpleTopAppBarr"; // Adjust path based on your project structure
import SimpleFooter from "../simpleFooter";
import theme from "../../moodyTheme";

const ServerOverload = () => {
  return (
    <>
      <SimpleHeader />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.colors.moodyDarkBackground,
          color: theme.colors.moodyWhite,
          padding: "2rem",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "#1A1A1D",
            borderRadius: "10px",
            padding: "2rem",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontFamily: "Quicksand, sans-serif",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Server Overload
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins, sans-serif",
              marginBottom: "1rem",
            }}
          >
            We're experiencing a temporary server overload due to high traffic.
            Our team is working hard to resolve this issue.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#b0b0b0",
            }}
          >
            Please check back later. We appreciate your patience!
          </Typography>
        </Container>
      </Box>
      <SimpleFooter />
    </>
  );
};

export default ServerOverload;
