import React, { useEffect, useState } from "react";
import { Box, Container, Typography, CircularProgress } from "@mui/material";
import SimpleTopAppBar from "../simpleTopAppBarr";
import SimpleFooter from "../simpleFooter";
import theme from "../../moodyTheme";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const UnsubscribePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const email = searchParams.get("email");

  const handleUnsubscribe = async () => {
    if (!email) {
      setMessage("No email found in the request. Please check the link.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://9rf6bjk1o9.execute-api.us-east-1.amazonaws.com/Prod/unsubscribe`,
        { email },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        setMessage("You have been unsubscribed successfully.");
      } else {
        setMessage("Failed to unsubscribe. Please try again.");
      }
    } catch (error) {
      console.error("Unsubscribe error:", error);
      setMessage("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const removeCookie = (cookieName: string) => {
    const domain = window.location.hostname; // Use the current domain
    const cookiePaths = ["/", "/unsubscribe", ""]; // Include common paths

    cookiePaths.forEach((path) => {
      document.cookie = `${cookieName}=; path=${path}; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    });

    console.log(`Removed cookie: ${cookieName} from domain: ${domain}`);
  };

  useEffect(() => {
    // Check if the referrer is from the unsubscribe page
    if (document.referrer.includes("theneptuneapp.com/unsubscribe")) {
      removeCookie("showEmailForm");
    }

    handleUnsubscribe();
  }, []); // Automatically call the unsubscribe function when the component loads

  return (
    <>
      <SimpleTopAppBar />
      <Box
        sx={{
          minHeight: "80vh",
          backgroundColor: theme.colors.moodyDarkBackground,
          display: "flex",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "#1A1A1D",
            padding: "2rem",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            color: theme.colors.moodyWhite,
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Quicksand, sans-serif",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Unsubscribe from Neptune
              </Typography>
              {email && (
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    marginBottom: "1rem",
                  }}
                >
                  Your email: <strong>{email}</strong>
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  mt: "1rem",
                  fontFamily: "Poppins, sans-serif",
                  color: message.includes("successfully") ? "green" : "red",
                }}
              >
                {message}
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
      <SimpleFooter />
    </>
  );
};

export default UnsubscribePage;
